<template>
  <div class="deposit-coin">
    <div class="title">Deposit Into Live Account ({{ user }})</div>
    <div class="qr-code">
      <img :src="Deposit.qr" alt="" :key="Deposit.qr" />
    </div>
    <div class="form-group mb-2">
      <label for="CoinDeposit">Coin Deposit</label>
      <b-form-select
        v-model.trim="coin"
        required
        id="CoinDeposit"
        :options="DepositList"
        value-field="Currency_ID"
        text-field="Currency_Name"
        @change="getCoin"
      ></b-form-select>
    </div>
    <div class="coin-address">
      <div class="box-address mb-1">
        <span>Address Wallet</span>
        <div class="address-wallet">
          <b-input-group class="">
            <template #prepend>
              <b-input-group-text>
                <b-button
                  v-clipboard:copy="Deposit.address"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                >
                  Copy
                </b-button>
              </b-input-group-text>
            </template>
            <b-form-input type="text" required v-model="Deposit.address" readonly></b-form-input>
          </b-input-group>
        </div>
      </div>
    </div>
    <div class="coin-warning">
      We only receive {{ getInfo.Currency_Name }} to this deposit wallet address. If you send
      another coin or token to this address, you can risk losing money
    </div>
    <div class="button-close text-center mt-2">
      <b-button variant="warning" @click="closePopup">Confirm</b-button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import filter from 'lodash/filter';

export default {
  props: {
    user: {
      type: Number,
      default: null,
    },
    typeAccount: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      address: '',
      coin: 3,
    };
  },
  computed: {
    ...mapGetters({
      Coin: 'wallet/Coin',
      Deposit: 'wallet/Deposit',
    }),
    DepositList() {
      return filter(this.Coin, (elm) => elm.Currency_StatusDeposit === 1);
    },
    getInfo() {
      return filter(this.Coin, (elm) => elm.Currency_ID === this.coin)[0];
    },
  },
  methods: {
    ...mapMutations(['onLoad', 'outLoad']),
    getCoin() {
      if (this.typeAccount === 'CTrade') {
        this.$store.dispatch('wallet/req_getDepositInfo', {
          coin: this.coin,
          subAccountID: this.user,
        });
      }
      if (this.typeAccount === 'MT5') {
        this.$store.dispatch('wallet/req_getDepositInfoMT5', {
          coin: this.coin,
          subAccountID: this.user,
        });
      }
    },
    onCopy() {
      this.$toastr.s('Copy Address Wallet Success', 'Successfully');
    },
    onError() {
      this.$toastr.e('Copy Address Wallet Fail,Please Try Again', 'Failed');
    },
    closePopup() {
      this.$emit('closePopup', true);
    },
  },
  watch: {
    DepositList: {
      handler() {
        this.coin = this.DepositList[0].Currency_ID ?? 3;
      },
    },
  },
  async mounted() {
    const current = await this.DepositList[0];
    this.coin = current.Currency_ID ?? 3;
    console.log(this.user);
    if (this.typeAccount === 'CTrade') {
      this.$store.dispatch('wallet/req_getDepositInfo', {
        coin: this.coin,
        subAccountID: this.user,
      });
    }
    if (this.typeAccount === 'MT5') {
      this.$store.dispatch('wallet/req_getDepositInfoMT5', {
        coin: this.coin,
        subAccountID: this.user,
      });
    }
  },
  created() {},
};
</script>

<style lang="scss">
.deposit-coin {
  max-width: 375px;
  margin: auto;
  .qr-code {
    text-align: center;
    img {
      max-width: 215px;
      margin: auto;
      width: 100%;
    }
  }
  .title {
    font-weight: 600;
    font-size: clamp(16px, 2vw, 18px);
    color: #2f394e;
    text-align: center;
    padding: 0 0 15px;
    border-bottom: 3px solid rgba($color: #000000, $alpha: 0.2);
  }
  .coin-address {
  }
  .button-close {
    button {
      font-weight: 600;
    }
  }
  .coin-warning {
    text-align: center;
    margin-top: 10px;
    font-weight: 600;
    font-size: clamp(14px, 2vw, 16px);
  }
  label {
    font-size: clamp(14px, 2vw, 16px);
    color: #6c757d;
    font-weight: 600;
    margin-bottom: 2px;
  }
  .box-address {
    width: 100%;
    span {
      font-size: clamp(14px, 2vw, 16px);
      color: #6c757d;
      font-weight: 600;
    }
    .address-wallet {
      margin-top: 2px;
      background: #FF5C00;
      border-radius: 5px;
      .input-group-text {
        padding: 0;
        border: 0;
        button {
          width: 70px;
          height: 35px;
          padding: 0;
          background: #8dc63f;
          border: 0;
          outline: none;
          border-radius: 5px;
          box-shadow: 0px 0px 5px 1px #0000009e;
          font-weight: 600;
        }
      }
      input {
        height: 35px;
        border: 0;
        background: transparent;
        color: #fff;
        font-weight: 600;
        &:hover,
        &:focus,
        &:focus-visible {
          outline: none;
          box-shadow: none;
          border: 0;
        }
      }
    }
  }
}
</style>
